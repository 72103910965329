(function ( $, config, undefined ) {

	if ( ! config.googleMapsApiKey || ! $( ".js-map" ).length  ) {
		return;
	}

	window.maps = function () {
		var api = google.maps;

		var getLatLng = function ( elem ) {
			var lat = parseFloat( elem.attr( "data-lat") );
			var lng = parseFloat( elem.attr( "data-lng") );

			if ( lat && lng ) {
				return new api.LatLng( lat, lng );
			}
		};

		var infoWindow;
		var openMarker = function () {
			if ( ! infoWindow ) {
				infoWindow = new api.InfoWindow();
			}

			infoWindow.setContent( this.content );
			infoWindow.open( this.map, this );
		};

		$( ".js-map" ).each(function () {
			var self    = $( this );
			var latLng  = getLatLng( self );
			var markers = self.find( ".js-map-marker" ).map(function () {
				var self   = $( this ).detach();
				var latLng = getLatLng( self );

				if ( latLng ) {
					return {
						latLng: latLng,
						marker: self.prop( "outerHTML" )
					};
				}
			});

			if ( ! markers.length ) {
				if ( ! latLng ) {
					return;
				}

				markers = $([{
					latLng: latLng,
					marker: self.attr( "data-address" )
				}]);
			} else if ( ! latLng ) {
				latLng = markers[0].latLng;
			}

			var bounds  = new api.LatLngBounds();
			var map     = new api.Map( this, {
				streetViewControl: false,
				mapTypeControl: false,
				rotateControl: false,
				gestureHandling: "cooperative",
				center: latLng,
				zoom: parseInt( self.attr( "data-zoom" ) ) || 11
			});

			markers = markers.map(function ( i, marker ) {
				bounds.extend( marker.latLng );
				marker = new api.Marker({
					position: marker.latLng,
					content: marker.content,
					map: map
				});

				if ( marker.content ) {
					marker.addListener( "click", openMarker );
				}

				return marker;
			});

			if ( markers.length > 1 ) {
				map.fitBounds( bounds );
			}

			$( window ).on( "resize", function () {
				api.event.trigger( map, "resize" );

				if ( markers.length > 1 ) {
					map.fitBounds( bounds );
				} else {
					map.setCenter( latLng );
				}
			});
		});
	};

	$.getScript( "https://maps.googleapis.com/maps/api/js?key=%s&callback=maps".replace( "%s", config.googleMapsApiKey ) );

})( jQuery, eso );